header.header nav.navbar {
    background-color: #1f1b1bab;
}

.footer-logo {
    width: 8rem !important;
}

.flight_card {
    .hourtime {
        font-size: 1.5rem;
    }
    .durationtime {
        font-size: 1rem;
    }

    .stopline {
        position: relative;
        display: block;
        width: 90%;
        height: 0.125rem;
        margin: 0.375rem auto;
        padding: 0;
        border-radius: 0.375rem;
        background-color: #68697f;
        line-height: 0;
        text-align: center;

        .depart_icon,
        .return_icon {
            position: absolute;
            right: 0;
            top: -10px;
            background: #fff;
            padding: 3px;
        }
        .return_icon {
            left: 0;
            right: auto;
            transform: rotate(180deg);
        }
        .stopdot {
            position: relative;
            top: -0.125rem;
            display: inline-block;
            width: 0.375rem;
            height: 0.375rem;
            margin: 0 4%;
            border-radius: 0.375rem;
            background-color: #d1435b;
            background-image: none;
            line-height: 0;
            box-shadow: 0 0 0 0.125rem #fff;
            zoom: 1;

            //
            display: none;
        }
    }
}

.search-bar {
    border-radius: initial;
    background-color: #c7cddfa6;
    color: #44495b;
    user-select: none;

    .form-group::after {
        display: none;
    }

    //  from to
    .to-holder,
    .from-holder {
        position: relative;
        overflow: hidden;
        border-radius: 10px 10px 0 0;
        margin: 0;
        background-color: #f8f9fb;
        padding: 7px;
        i {
            margin: 0 0.25rem;
        }
        .text-input {
            width: 70%;
            background-color: transparent;
        }
        input:focus {
            outline: none !important;
        }
    }
    .to-holder {
        background-color: #f5f6f9;
        border-radius: 0 0 10px 10px;
    }

    //passenger
    .passenger-holder {
        .passenger-number-holder,
        .passenger-label-holder {
            position: relative;
            overflow: hidden;
            border-radius: 10px 10px 0 0;
            margin: 0;
            background-color: #f8f9fb;
            padding: 7px;
            min-height: 40px;
            i {
                margin: 0 0.25rem;
            }
            .text-input {
                width: 70%;
                background-color: transparent;
            }
        }
        .passenger-label-holder {
            background-color: #f5f6f9;
            border-radius: 0 0 10px 10px;
        }

        .passenger-form {
            position: absolute;
            top: 90px;
            background-color: #f5f6f9;
            border-radius: 10px;
            padding: 1rem;
            text-align: center;
            left: 0;
            right: 0;
            z-index: 100;

            label {
                font-size: 12px;
                text-align: left;
                margin: 0;
                padding: 5px 0;
                span {
                    font-size: 11px;
                }
            }
            .passenger-input {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                grid-template-rows: 1fr;
                gap: 0px 10px;
                grid-template-areas: ". . .";
                width: 100%;
                height: 100%;
                text-align: center;

                .btn {
                    width: 100%;
                    display: inline-block;
                    text-align: center;
                }

                input {
                    width: 100%;
                    display: block;
                    text-align: center;
                }
            }
        }
    }

    //daterangepicker
    .daterangepicker-input-holder {
        position: relative;
        overflow: hidden;
        border-radius: 10px;
        margin: 0;

        .date-to-holder,
        .date-from-holder {
            background-color: #f8f9fb;
            padding: 0.25rem;

            .date-holder {
                font-size: 12px;
                font-weight: 600;
                color: #44495b;
                i {
                    position: absolute;
                    left: 15px;
                    top: 50%;
                    margin-top: -6px;
                }
                .date-month {
                    font-weight: bold;
                }
                .date-daynum {
                    font-size: 32px;
                    line-height: 29px;
                }
                .date-day {
                    font-weight: normal;
                }
            }
        }

        .date-to-holder {
            background-color: #f5f6f9;

            i {
                transform: scaleX(-1);
                right: 15px;
                left: auto !important;
            }
        }
    }
}
